@import "src/variables";

.splash {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  background-image: url("https://i.imgur.com/gQO48Eh.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  background-attachment: fixed;
  height: 500px;

  .page-intro {
    text-align: center;
  }

  .main-title {
    margin: 0;
    font-weight: normal;
    color: $color-main;
    font-size: 60px;
    font-family: $font-main;
  }

  .main-subtitle {
    margin: 12px 0 60px 0;
    font-weight: normal;
    color: $color-main;
    font-size: 28px;
    font-variant: small-caps;
    font-weight: bold;
    font-family: $font-main;
  }
}

.main-btn {
  padding: 18px 24px 18px 24px;
  font-size: 14px;
  margin: 12px 12px 12px 0px;
  background-color: $color-main;
  color: $color-background;
  transition: all 0.5s;

  &:hover {
    background-color: $color-boxes;
    color: $color-main;
  }
}
