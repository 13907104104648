@import url("https://fonts.googleapis.com/css2?family=Karla&family=Spectral&display=swap");
@import "./variables";
@import "./components/About/about";
@import "./components/Contact/contact";
@import "./components/Footer/footer";
@import "./components/Gallery/gallery";
@import "./components/Navigation/navigation";
@import "./components/Projects/projects";
@import "./components/Skills/skills";
@import "./components/Splash/splash";
@import "./components/Statement/statement";

* {
  box-sizing: border-box;

  &:focus {
    box-shadow: none;
  }
}

html {
  scroll-behavior: smooth;
}

body,
html {
  background-color: $color-background;
  color: $color-main;
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
  height: 100%;
  font-family: $font-text;
}

a {
  color: $color-titles;

  &:hover {
    color: lighten($color-titles, 25%);
    transition: $transition;
  }
}

img {
  object-fit: cover;
  height: auto;
  width: auto;
}

section {
  padding: 60px 0;
  margin: 10px;
}

.section-title {
  position: relative;
  margin: 0 0 60px 0;
  text-align: center;
  color: $color-titles;
  font-family: $font-main;
  font-weight: bold;

  &:after {
    position: absolute;
    display: block;
    content: " ";
    width: 200px;
    height: 5px;
    background: $color-main;
    left: 50%;
    bottom: -16px;
    margin-left: -100px;
  }
}

.section-intro {
  margin: 0 0 40px;
  text-align: center;
  color: $color-titles;
}

.container {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;
}

.btn {
  margin: 12px 12px 16px 0px;
}
