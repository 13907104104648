@import "src/variables";

.about {
  background-color: $color-boxes;
  padding: 0;
}

.about-img {
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.about-text {
  margin: 0 auto;
  display: block;
  text-align: justify;
  padding: 50px;
}

@media (min-width: 768px) {
  .about-img {
    padding-right: 15px;
  }
}
