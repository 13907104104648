@import "src/variables";

.gallery {
  padding: 60px 0 10px 0;
  background-color: $color-boxes;
}

.gallery-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 72px 0 0 0;
}

.gallery-item {
  position: relative;
  margin: 0;
  flex: 0 0 50%;
  max-width: 50%;
  text-align: center;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    position: absolute;
    display: none;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-background;
    opacity: 0.6;
  }

  figcaption {
    position: absolute;
    display: none;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    color: $color-main;
    font-family: $font-main;
    font-size: 18px;
  }

  &:hover:before,
  &:hover figcaption {
    display: block;
  }
}

@media (min-width: 992px) {
  .gallery-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
